import get from 'lodash/get';
import { limiter } from '@/services';

export default defineNuxtRouteMiddleware(async (to) => {
  try {
    await limiter();
  } catch (e) {
    // Confirmed: DO converts 503s to 504s for some reason.
    // Use 429 instead. Thanks for nothing DO.
    if (get(e, 'response.status') === 429) {
      return await navigateTo({ name: 'maintenance', query: to.query });
    }
  }
});
